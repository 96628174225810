import React, { useEffect, useRef, useState } from "react";
// import Cups                                   from '../images/0000.jpeg';
// import Cups                                   from '../images/cups_unpaid.jpg';
import Cups                                  from '../images/cups02.jpg';
import CupsMobile                            from '../images/cups_mobile.jpg';

import HerbsAndOil                            from '../images/herbsAndOil.jpg';

import Emad                                   from '../images/Emad-Al-dakhil.jpg';
import FormImg                                from '../images/FormExample.png';

import MobileCertificate04                    from '../images/cert/0403.jpg';
import MobileCertificate05                    from '../images/cert/05.jpg';
import MobileCertificate05ar                  from '../images/cert/05.ar.jpg';
import MobileCertificate06                    from '../images/cert/06.jpg';
import MobileCertificate07                    from '../images/cert/07.jpg';

import Certificate03                          from '../images/cert/sideToSide/03.jpg';
import Certificate04                          from '../images/cert/sideToSide/04.jpg';
import Certificate05                          from '../images/cert/sideToSide/05.jpg';
import Certificate06                          from '../images/cert/sideToSide/06.jpg';
import Carousel                               from 'react-bootstrap/Carousel';

const Contents = () => {
    
    const mainRef = useRef(null);
    const [viewWidth, setViewWidth] = useState(null);
    
    useEffect(() => {
        mainRef.current && setViewWidth(mainRef.current.offsetWidth);        
    }, []);
    
    return (
        <main ref={mainRef} className="container">
            <SectionMainTitle/>
            <ImageSection viewWidth={viewWidth}/>

            <SectionTitle title="Der Prophet sagte" />
            <HadithSection />

            {/* 
                <SectionTitle id="about" title="Kursinhalt f&uuml;r Grundkurs Hijama" />
                <Course />
             */}

            <SectionTitle id="about" title="&Uuml;ber uns" />
            <AboutUslSection viewWidth={viewWidth}/>

            <SectionTitle title="Schr&ouml;pf Typen" />
            <TypesOfHijama />
            
            <SectionTitle title="Schr&ouml;pfen vorteile" />
            <MedicalBenefits />

            <SectionTitle title="&Ouml;l und Kr&auml;uter" />
            <OilAndHerbs />

            <SectionTitle title="Formular zum ausf&uuml;llen" />
            <FormExample />

            <SectionTitle title="Preis" />
            <Price />
        </main>
    );
};

const SectionMainTitle = () =>
(
    <section className="title-container">
        <h1 className="main-title title">Ihre schr&ouml;pfe Praxis f&uuml;r Frauen und M&auml;nner</h1>
    </section>  
);

const ImageSection = ({ viewWidth }) =>
(
    <section className="main-head-img">
        <img className="img-fluid cups-img" src={viewWidth > 420 ? Cups : CupsMobile} alt="cups" />
    </section>
);

const SectionTitle = ({ title, id = "" }) =>
(
    <section id={id} className="title-container">
        <h2 className="title">{title}</h2>
    </section>
);

const HadithSection = () =>
(
    <section className="hadith">
        <p className="ar-text">قال عليه الصلاة والسلام: "إنَّ أَفْضَلَ ما تَدَاوَيْتُمْ به الحِجَامَةُ، أَوْ هو مِن أَمْثَلِ دَوَائِكُمْ".</p>
        <p>Eure besten Heilverfahren sind Hijama.</p>
        <p>Tedavi olman&#x131;n en hay&#x131;rl&#x131;s&#x131; Hacemat'd&#x131;r.</p>
        <p>ilaçi më i mirë me të cilin mund të shëroheni, është hixhameja.</p>
        <p>Ako u onome čime se liječite uistinu ima lijeka, onda je to u hidžami.</p>
    </section>
);

const CarouselSection = ({ viewWidth }) =>
{
    const desktopCertificatesList = [
        Certificate06,
        Certificate05,
        Certificate04,
        Certificate03
    ];
    const mobileCertificatesList = [
        MobileCertificate07,
        MobileCertificate06,
        MobileCertificate05,
        MobileCertificate05ar,
        MobileCertificate04
    ];

    const imagesData = viewWidth > 767 ? desktopCertificatesList : mobileCertificatesList;

    return (
        <Carousel interval={2000}>
            {
                imagesData.map((item, idx) =>
                    <Carousel.Item key={idx}>
                        <img className="d-block w-100" src={item} alt={`slide-${idx}`}/>
                    </Carousel.Item>
                )
            }
        </Carousel>
    )
};

const Course = () => {
    return (
        <section className="container about-us">
            <div className="row about-us-description mb-5">
                2 Tage Tageskurs Sa/So<br/>
                17/18 Juni 2023<br/>
                von 9.00 -16.00 Uhr<br/>
                (1 Stunde Mittagspause)<br/>
                <br/>
                Kursort: Pumpwerkstr.26 8105 Regensdorf <br/>
                <br/>
                <h3 class="p-0">Kurskosten:</h3>
                Fr.250.- inkl. Eine Hijama Behandlung für den Kursteilnehmer<br/>
                Der Kurs wird mindestens mit 5 Kursteilnehmer durchgeführt.<br/>
                Jeder Kursteilnehmer erhält nach dem Kurs eine Bestätigung den Kurs besucht <br/>
                und am Unterricht teilgenommen hat.<br/>
                <br/>
                <h3 class="p-0">Kursmaterialien :</h3>
                Im Kurs vor Ort <br/>
                Material: (Was braucht man unbedingt im Hijama )<br/>
                Hijama Gläser,<br/>
                Schröpfen : Nass ,Trocken und Magnet 
                Erste Hilfe , Handschuhe, Haushaltpapier,Hygienebeutel 
                Desinfiziere – Sauberkeit (Betadine – Wundereinigen), Massageoel
                Nadelstift , Abdeckrolle für Liege <br/>
                <br/>
                1.Tag: Theorie: Was bedeutet Hijama und was gibt es für Methode  (Krankheiten, Beschwerden,Balance wieder herstellen, (Immunsystem aufbauen – stärken<br/>
                <br/>
                2.Tag: Praxis: Welche Schröpfarten gibt es<br/>
                Wann und wie werden die Schröpfarten spezifisch angewendet<br/>
                Durchführung  von Praktische Anwendung<br/>
                <br/>
                Vielen Dank für Ihr Interesse und Teilnahme am Kurs 
            </div>
        </section>
    )
};

const AboutUslSection = ({ viewWidth }) =>
(
    <section className="container about-us">
        <div className="row about-us-description mb-5">
            <div className="col-md-7">
                <h3>Imam Emad Al Dakhil</h3>
                <ul>
                    <li className="my-2">In Kroatien 3 Jahre Zahnmedizin studiert.</li>
                    <li className="my-2">Seit &uuml;ber 25 Jahre bis weiteres als Imam t&auml;tig, sowie am Interkulturellen zusammenleben in der Gesellschaft.</li>
                    <li className="my-2">Seit 5 Jahren als Pfleger t&auml;tig in Einem Alters und Spitexzentrum.</li>
                    <li className="my-2">Weiterbildung: SRK Kurs, Schr&ouml;pftherapie, Akupunktur Master Tang</li>
                    <li className="my-2">Ausbildung: Hijama - Akademie in England</li>
                    <li className="my-2">Sprache: Deutsch, Englisch, Arabisch, Serbokroatisch,</li>
                </ul>

                <h3 className="mt-5">F&uuml;r Frauen</h3>
                <ul>
                    <li>
                        Safa Al Dakhil<br/>
                        Ausgebildete Krankenschwester
                    </li>
                </ul>
                <p className="mt-5">Mit Quran audio Melodie</p>

            </div>
            <div className="col-md-5 mt-5 mt-md-0 text-center text-md-end img-fluid">
                <img className="w-100" src={Emad} alt="Emad Al-Dakhil" />
            </div>
        </div>
        <CarouselSection viewWidth={viewWidth} />
    </section>
);

const TypesOfHijama = () =>
(
    <section className="hijama-types">
        <h4>
        </h4>Schr&ouml;pf Typen

        <h4>Nasses Schr&ouml;pfen:</h4>
        <p>
            Ist eine alte Form der Alternativmedizin und (Islamische).<br/>
            Es kann zur Linderung und Behandlung von Schmerzen, Entz&uuml;ndungen und zur Verbesserung der Durchblutung eingesetzt werden.
        </p>

        <h4>Trocken Schr&ouml;pfen:</h4>
        <p>
            Eine Therapieform, die Schmerzen lindert und die Durchblutung f&ouml;rdert.<br/>
            Das Immunsystem wird dadurch aktiviert .<br/>
            Am R&uuml;cken gibt es zu allen Organen Reflexzonen die durch das Schr&ouml;pfen angeregt oder beruhigt werden.
        </p>

        <h4>Sport Hijama:</h4>
        <p>
            Im Sportbereich ist Schr&ouml;pfen eine Hervorragende und geeignete Therapieform.<br/>
            Um schlecht durchblutende Muskeln oder Organe zu regenerieren.
        </p>

        <h4>Schr&ouml;pfen mit &Ouml;l:</h4>
        <p>
            In diesem Fall wird Schr&ouml;pfen als Massage verwendet.<br/>
            In vielen F&auml;llen sind die Muskeln steif und angespannt und ben&ouml;tigen eine lokale Massage, bis sie sich wieder normalisiert haben.
        </p>

        <h4>Schr&ouml;pfen mit Massagen Techniken</h4>
        <p>
            Mit dem Oel werden in vielen F&auml;llen die angespannten und zum teil Steife Muskeln die mit einer Drucktechnik massiert wierd.<br/>
            Mit der Lokalen Behandlung der Muskeln und Sehnen k&ouml;nnen sich die angespannten Muskeln wieder normalisiern.
        </p>

        <h4>Magnetisches Schr&ouml;pfen:</h4>
        <p>
            Das Magnetische Schr&ouml;pfen ist in der Lage die eigen elektrischen Str&ouml;me in unserem K&ouml;rper in Bewegung zu bringen und zu aktivieren.<br/>
            Der K&ouml;rper pumpt so frischen Sauerstoff in unsere Organe.
        </p>

        <h4>Akupunktur nach Master Tung:</h4>
        <p>Mit wenigen gezielten Nadelstichen an Punktuellen Mediane die unsere Nervenbahnen stimulieren k&ouml;nnen so gezielte erfolge erreicht werden um eine verbesserte Alternativ Behandlungen in Schmerztherapien zu haben.</p>

        <p className="primary-text">Mit Quran audio Melodie</p>
    </section>
);

const MedicalBenefits = () =>
(
    <section className="medical-benefits-list">
        <p>Schr&ouml;pfgesellschaft wird diese Behandlung bei folgenden Leiden angewendet</p>
        <ul>
            <li><p>Blutkrankheiten wie An&auml;mie.</p></li>
            <li><p>Rheumatische Erkrankungen wie Arthritis.</p></li>
            <li><p>Fruchtbarkeitsproblemen und Gyn&auml;kologischen Problemen.</p></li>
            <li><p>Hautprobleme wie Ekzeme.</p></li>
            <li><p>Bluthochdruck.</p></li>
            <li><p>Migr&auml;ne / Kopfschmerzen.</p></li>
            <li><p>Angst und Depression.</p></li>
            <li><p>Krampfadern.</p></li>
            <li><p>Fersensporen.</p></li>
            <li><p>Diskushernie.</p></li>
            <li><p>Schilddr&uuml;se.</p></li>
            <li><p>Tinnitus.</p></li>
            <li><p>Gicht.</p></li>
        </ul>
    </section>
);

const OilAndHerbs = () =>
(
    <section className="herbs-and-oil">
        <div className="row">
            <div className="col-md-6">
                <p>M(a.s)Medizin Hijama Kombination mit</p>
                <p><strong>&Ouml;l wie</strong>:<br/>
                Schwarzk&uuml;mmel &Ouml;l ,
                Olive &Ouml;l Sesam&ouml;l ,
                K&uuml;rbis &Ouml;l ...</p>
                <p>Und</p>
                <p><strong>Kr&auml;uter</strong> : K&uuml;rbiskernen, Nelken, Bockshornklee, Gerste, Knoblauch, Schwarzk&uuml;mel, Indische Kostuswurzel, Ingwer, Sesamaki, Gummiarabicum. ... ECT.</p>
            </div>
            <div className="col-md-6">
                <img className="img-fluid herbs-and-oil" src={HerbsAndOil} alt="cups" />
            </div>
        </div>
    </section>
);

const FormExample = () =>
(
    <section className="form example">

        <div className="row">
            {/* <div className="col-md-8">
                <h4 className="mb-4">Bevor wir beginnen, werden wir Sie bitten, dieses Formular auszuf&uuml;llen</h4>
                <h5>Klienten informationsblatt</h5>
                <p>Name, Vorname, Geburtsdatum, Adresse, Mobile, Email</p>

                <h5 className="mt-5">Es enthält fünf Fragen zu Ihrer aktuellen Gesundheitssituation</h5>

                <p>Haben Sie Hepatitis C</p>
                <p>Haben Sie eine frische Operation</p>
                <p>Haben Sie Herzprobleme</p>
                <p>Haben Sie Blutanämie</p>
                <p>Haben Sie Diabetes</p>
            </div> */}
            <div className="col-md-12 text-center">    
                <img className="img-fluid w-60" src={FormImg} alt="form example" />
            </div>
        </div>
    </section>
);

const Price = () =>
(
    <section className="form example">
        <div>
            <p>Tägliche Termine</p>
            <p>Montag - Sonntag  ab  17.30 Uhr</p>
            <p>Verbindliche Terminvereinbarung per </p>
            <p>Telefon Whatsapp oder über Email</p>
            <h3 className="primary-text">Preis: Fr.80.-</h3>
        </div>
    </section>
);
 
export default Contents;