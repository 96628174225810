import React               from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Icon                from '../images/main-logo-text-converted.svg'

export const SEO = ({ description, pathname, children }) => {
  const { description: defaultDescription, image, siteUrl } = useSiteMetadata()

  const seo = {
    title: 'Hijama (Schröpfe) praxis in Regensdorf',
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    siteUrl: `${siteUrl}${pathname || ``}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow, noodp" />
      <link rel="icon" href={Icon} />
      <meta name="google-site-verification" content="3DfscT6PR2TKjpP6SHRM_AqcY4Dm8jFDFkOQjrHIfDc" />

      <meta property="og:locale" content="de_DE" />
      <meta property="og:site_name" content="hijama-master.ch" />
      <meta property="og:title" content="Hijama master praxis - hijama-master.ch in regensdorf" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content="https://hijama-master.ch/static/cups02-38db172877f544851c359ab05cb246c3.jpg"/>
      <meta property="og:image:type" content="image/jpg" />

      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description}/>
      <meta property="twitter:image" content={seo.image}/>

      <meta name="keywords" content="Hijama, schröpfen, Hijama Regensdorf, Hijama Regensdorf, Zurich, schröpfen Praxis In Regensdorf, Emad Al-Dakhil"/>
      {children}
    </>
  )
}