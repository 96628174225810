import React    from "react";
import WhatsApp from '../images/WhatsApp.svg';
import Phone    from '../images/phone.svg';
import Mail     from '../images/mail.svg';
import Location from '../images/location.svg';
import Logo     from '../images/main-logo-text-red-white.svg';
 
const Footer = () => (
    <footer id="footer">
        <div className="container">
            <div className="row">
                <div className="col-12 text-end footer-logo-wrapper">
                    <div className="img-wrapper">
                        <img className="footer-logo" alt="" src={Logo} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h3 className="mt-3 mb-5">Kontakt</h3>
                    <ul>
                        <li>
                            <a rel="noopener noreferrer" href="mailto:kontakt@hijama-master.ch">
                                <span>
                                    <img alt="Email" src={Mail} />
                                </span>
                                kontakt@hijama-master.ch
                            </a>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" href="tel:+41764106740">
                                <span>
                                    <img alt="Mobile" src={Phone} />
                                </span>
                                +4176 410 67 40
                            </a>
                        </li>
                        <li>
                            <a className="whatsapp" rel="noopener noreferrer" target="_blank" href="https://wa.me/+41764106740">
                                <span>
                                    <img className="whatsapp-img" alt="" src={WhatsApp} />
                                </span>
                                Whatsapp
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <h3 className="mt-3 mb-5">Adresse</h3>
                    <ul>
                        <li>
                            <div className="addrees">
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.google.com/maps/place/Pumpwerkstrasse+26,+8105+Regensdorf/@47.4443316,8.4600431,18z/data=!4m5!3m4!1s0x47900ca92bbb345b:0x709b5f9eb4eb2e47!8m2!3d47.4443726!4d8.460531"
                                    target="_blank">
                                    <span>
                                        <img className="location" alt="Address" src={Location} />
                                    </span>
                                    <div className="d-inlin-block">
                                        <address>
                                            Pumpwerkstr 26<br/>
                                            8105 Regensdorf
                                        </address>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12 text-center copy-rights">
                    <p>&#169; {(new Date().getFullYear())} | hijama master</p>
                </div>
            </div>
        </div>
    </footer>
);
 
export default Footer;