import React from "react";
import Logo  from "../images/main-logo-text-full-white.svg";
 
const Header = () => {
    
    const scrollToBottom = id => document.getElementById(id).scrollIntoView({ behavior: "smooth" });

    return (
        <header id="header">
            <div className="container">
                <div className="row">
                    <div className="col-6 text-start">
                        <img className="logo" src={Logo} alt="logo" />
                    </div>
                    <div className="col-6 mt-4 mt-sm-0 nav">
                        <span className="px-3" onClick={() => scrollToBottom("about")}>&Uuml;ber uns</span>
                        <span className="px-3" onClick={() => scrollToBottom("footer")}>Kontakt</span>
                    </div>					
                </div>
            </div>
        </header>
    )
};
 
export default Header;