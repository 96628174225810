import * as React  from "react";
import Header      from '../components/header';
import Footer      from '../components/footer';
import Contents    from '../components/contents';
import ScrollToTop from '../components/scrollToTop';
import { SEO }     from '../components/seo';

import "../../node_modules/bootstrap/scss/bootstrap.scss";

import './styles.scss';

const IndexPage = () => {
    return (
        <React.Fragment>
            <Header />
            <Contents />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    );
};

export default IndexPage;

export const Head = () => <SEO />;